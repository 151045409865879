import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";

class AmountForm extends React.Component {
  constructor(props) {
    super(props);
    this.amountInputRef = React.createRef(); // Create a reference for the input element
  }

  componentDidUpdate(prevProps) {
    // Check if props.amount changed and it's an empty string, then focus on the input
    if (this.props.amount !== prevProps.amount && this.props.amount === "") {
      this.amountInputRef.current.focus();
    }
  }

  render() {
    const { handleInputChange, formData, toggleValue, amount } = this.props; // Destructure props

    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="Amount">
              {this.props.amount ? "Amount" : "Other Amount"}{" "}
              <span className="required">*</span>
            </label>
            <div className="input-group">
              <input
                type="number"
                defaultValue={amount}
                className="form-control"
                id="amount"
                placeholder="Amount"
                onChange={handleInputChange}
                required
                step="0.01" // Allow decimal numbers with two decimal places
                ref={this.amountInputRef} // Assign the reference to the input element
                min="0"
              />
              <span className="input-group-addon">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </div>
          </div>
        </div>


<div className="col-md-12">
              <div className="form-group">
                <div className="d-flex flex-wrap align-items-center">
                  <label
                    style={{ display: "flex", alignItems: "center" }}
                    className="coverFeeOption"
                  >
                    <input
                      type="checkbox"
                      onChange={(e) => {!window.location.href.includes("monthly") && toggleValue(e, "monthly")}}
                      checked={formData.monthly}
                      style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                    />&nbsp;&nbsp;Make it Monthly?
                  </label>
                  {formData.monthly && <p style={{color: "black"}}>Your monthly charge of ${amount} will appear on your credit card statement each month</p>}
                </div>
              </div>
            </div>
      </>
    );
  }
}

export default AmountForm;
