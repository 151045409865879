import React from 'react';
import Select from 'react-select'; // Import Select from react-select

const DROPDOWN_STYLE = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      borderWidth: "0px",
      minHeight: "0px",
      boxShadow: "0px",
    };
  },
  menu: (baseStyles, state) => {
    return {
      ...baseStyles,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px",
      fontSize: "0.9rem",
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: state.isFocused ? "#DEEBFF" : "white",
      color: "inherit",
    };
  },
};


class SingleChoiceDropdown extends React.Component {
  getOption = (choice) => {
    if (choice) {
      return { value: true, label: "Yes" };
    } else {
      return { value: false, label: "No" };
    }
  }

  handleOnChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    console.log(this.props.value);

    if (selectedOption.value !== this.props.value) {
      this.props.toggleValue();
    }

  }


  render() {
    const {
      value,
      label,
      identifier,
      outerClass,
      isRequired
    } = this.props; // Destructure props

    const choiceOptions = [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ];

    return (
      <>
          <div className={outerClass ? outerClass : "col-lg-6"}>
            <div className="form-group">
            <label htmlFor={identifier}>
  {label}
  {isRequired && <span className="required"> *</span>}
</label>

              <Select
                id={identifier}
                defaultValue={this.getOption(value)}
                value={this.getOption(value)}
                className="form-control"
                options={choiceOptions}
                styles={DROPDOWN_STYLE}
                onChange={this.handleOnChange}
              />
            </div>
          </div>

      </>
    );
  }
}

export default SingleChoiceDropdown;
