import React from 'react';
import '../assets/css/FakeApplePayButton.css'; // Import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';



const ApplePayButton = ({handleClick}) => {


  return (
    <div className="fake-apple-pay-button" onClick={handleClick}>
      <div alt="Apple Pay Logo" className="apple-pay-logo"> 
      Pay with <FontAwesomeIcon icon={faApple}  className="credit-card-icon" />Pay
      </div>
    </div>
  );
};

export default ApplePayButton;
