import React from "react";
import "../assets/css/DonationOptions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

class FANDonationOptions extends React.Component {
  renderButtons = () => {
    let amounts = [10000, 5000, 2500, 1000, 500, ""];

    if (this.props.pledgeAmount) {
      const pledgeAmount = parseFloat(this.props.pledgeAmount); // Parse pledgeAmount as a float
    
      // Calculate amounts and round each to the nearest integer if it's an exact integer, otherwise round to 2 decimal places
      amounts = [
        Math.round(pledgeAmount * 2),
        Math.round(pledgeAmount * 1.75),
        Math.round(pledgeAmount * 1.5),
        Math.round(pledgeAmount * 1.25),
        Math.round(pledgeAmount),
        "" // Empty string
      ];
    }
    
    
    

    return (
      <>
        {amounts.map((amount, index) => (
          <li key={index}>
            <button
              type="button"
              className={`form-btn ${
                (this.props.amount === amount)  || (amount === "" && !amounts.includes(this.props.amount) ) ? "active" : "inactive"
              }`}
              onClick={() => this.props.handleAmountClick(amount)}
            >
              <span className="button-content">
                <FontAwesomeIcon
                  icon={(this.props.amount === amount) || (amount === "" && !amounts.includes(this.props.amount) ) ? faSquareCheck : faSquare}
                  className="checkbox-icon icon-lg"
                />
                { amount === "" ? (<>&nbsp;Other</>) : (<>&nbsp;${amount}</>)}
              </span>
            </button>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <div className="row causes-area causes-single-items">
        <div className="col-md-12 item">
          <ul className="group">{this.renderButtons()}</ul>
        </div>
      </div>
    );
  }
}

export default FANDonationOptions;
