import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";

class SpringBehalfContactForm extends React.Component {
  render() {
    const { formData, handleInputChange, toggleValue, handleChoiceUpdate } =
      this.props; // Destructure props

    const imageOptions = [
      {
        value: "TributeOption1",
        label: "Option 1",
        src: "/spring.gif",
      }
    ];

    return (
      <>
        <div className="col-md-12">
          <p style={{ color: "black" }}>
            <small>
              Your name and email address will be shared with the honouree. Your
              donation amount will remain confidential.
            </small>
          </p>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfFirstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfFirstName"
              placeholder="First Name"
              value={formData.behalfFirstName}
              onChange={handleInputChange}
              required={formData.behalf}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfLastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfLastName"
              placeholder="Last Name"
              value={formData.behalfLastName}
              onChange={handleInputChange}
              required={formData.behalf}
            />
          </div>
        </div>

        <SingleChoiceDropdown
          value={formData.ecard}
          toggleValue={(e) => toggleValue(e, "ecard")}
          label={"Send a message?"}
          identifier="ecard"
          outerClass="col-md-12"
        />

        {formData.ecard && (
          <>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="behalfEmail">
                  Their Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="behalfEmail"
                  placeholder="Email"
                  value={formData.behalfEmail}
                  onChange={handleInputChange}
                  required={formData.ecard}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="behalfNote">Message <span className="required">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="behalfNote"
                  placeholder="Your message"
                  value={formData.behalfNote}
                  onChange={handleInputChange}
                  required={formData.ecard}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default SpringBehalfContactForm;