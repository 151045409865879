import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import FANNavbar from "./FANNavbar"
import Footer from "./Footer";
import FANThankYouPage from "./FANThankYouPage"
import BlueSection from "./BlueSection"
import { ReactComponent as BackgroundSVG } from "../assets/img/background.svg";

class FANCampaignComponent extends Component {
  constructor(props) {
    super(props);

    // Accessing query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const pledgeAmount = queryParams.get('pledgeAmount');
    const barcode = queryParams.get('code');
  
    let initialAmount = 1000; // Default value if pledgeAmount is null
    if (pledgeAmount) {
      initialAmount = Number.isInteger(parseFloat(pledgeAmount)) ? parseInt(pledgeAmount) : parseFloat(pledgeAmount).toFixed(2);
    }

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: initialAmount,
      payFees: false,
      firstName: "",
      pledgeAmount: pledgeAmount,
      barcode: barcode
    };
  }

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
  
  }
  
  

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };



  updateFirstName = (firstName) => {
    this.setState({firstName: firstName})
  }

  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <FANNavbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          ><BackgroundSVG /></div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-1">
              <div className="col-lg-6 info">
        
              </div>
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? <FANThankYouPage amount={this.state.amount} 
                updateHasDonated={this.updateHasDonated} firstName={this.state.firstName}  /> : (
                  <StripeContainer updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  amount={this.state.amount}  
                  updateAmount={this.updateAmount}
                  payFees={this.state.payFees}
                  togglePayFees={this.togglePayFees}
                  updateFirstName={this.updateFirstName}
                  formType="FAN"
                  pledgeAmount={this.state.pledgeAmount}
                  barcode={this.state.barcode}
                  />
                )}
                
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default FANCampaignComponent;