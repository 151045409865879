import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ThankYouPage from "./ThankYouPage";
import BlueSection from "./BlueSection";
import { ReactComponent as BackgroundSVG } from "../assets/img/background.svg";
import spring from "../assets/img/spring.gif";
import SpringLockup from "../assets/img/spring-lockup.png";
import YouTube from "react-youtube"; // Importing the react-youtube component


class SpringCampaignComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: 100,
      payFees: false,
      firstName: "",
      isMobile: false,
      names: ["Cameron", "Alice", "Carmen", "Barbara"], // Predefined list of names
      currentNameIndex: 0, // Index to track the currently rendered name
      fadeOpacity: 1 // Initial opacity for fade effect
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize(); // Call initially to set state based on screen size
    // Start rendering names
    this.renderNextName(); // Start rendering the first name
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    // Check if screen width is less than or equal to 768 pixels (typical mobile width)
    const isMobile = window.innerWidth <= 990;
    this.setState({ isMobile: isMobile }); // Display on desktop, hide on mobile
  };


  updateFirstName = (firstName) => {
    this.setState({ firstName: firstName });
  };


// Function to render the next name from the list
renderNextName = () => {
  const { names, currentNameIndex } = this.state;
  const nextIndex = (currentNameIndex + 1) % names.length; // Calculate the next index, looping back to 0 if it reaches the end

  const step = 0.01; // Define the step for opacity transition
  let opacity = 1; // Initial opacity value

  // Fade out
  const fadeOutInterval = setInterval(() => {
    opacity -= step; // Reduce opacity by the defined step
    this.setState({ fadeOpacity: opacity });

    if (opacity <= 0) {
      clearInterval(fadeOutInterval); // Stop the fade out interval
      // Set the next name
      this.setState({ currentNameIndex: nextIndex }, () => {
        // Fade in
        const fadeInInterval = setInterval(() => {
          opacity += step; // Increase opacity by the defined step
          this.setState({ fadeOpacity: opacity });

          if (opacity >= 1) {
            clearInterval(fadeInInterval); // Stop the fade in interval
            // Call renderNextName again after the specified delay
            setTimeout(this.renderNextName, 3000); // Change this value to control the delay between each name
          }
        }, 25); // Change this value to control the fade in transition speed
      });
    }
  }, 25); // Change this value to control the fade out transition speed
};



 

  render() {
    const { names, currentNameIndex, fadeOpacity } = this.state;
    const currentName = names[currentNameIndex]; // Get the current name to render

    const opts = {
      width: "100%",
      height: "360px",
      playerVars: {
        autoplay: 1,
      },
    };

    return (
      <>
        <BlueSection />
        <div className="donation-area bg-gray">

          <div
            className="fixed-bg spring"
            
            id="bg-wrap"
          >
            <BackgroundSVG />
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-5">

              <div className="col-lg-6 info">

                <form className="title spring-info" style={{
                  backgroundColor: "#ffffff",
                  paddingTop: "35%"
                }}>
                  <p>
                  <div className="names-container" style={{ opacity: fadeOpacity }}>
                      <p className="fade-in-out">{currentName}</p>
                    </div>
                  </p>
                  <p>
                    <img src={spring} alt="Spring GIF" />

                  </p>
                </form>

                {!this.state.isMobile && <>
                  <form className="mt-4">
                  <p>
                  This Spring, nurture the potential of youth by acknowledging a special person in your life through our Thank You Tree campaign.
                  </p>
                  <p>
                    Make a donation via the form provided and select who you
                    would like to thank!
                  </p>
                  <p>Thank someone special. Make a donation in their honour.</p>
                </form>

                <form className="mt-4">
                  <p>
                  Your support will help fund matches like Theresa and Rosemary. Theresa went from being a quiet and reserved girl to an inspirational young woman, graduating from high school and following in the footsteps of her Big Sister by getting a job in healthcare.
                  </p>
                  <YouTube videoId="jS92EDCnA9Y" opts={opts} />
                </form>
                </>}


              </div>
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? (
                  <ThankYouPage
                    amount={this.state.amount}
                    updateHasDonated={this.updateHasDonated}
                    firstName={this.state.firstName}
                  />
                ) : (
                  <StripeContainer
                    updateIsLoading={this.updateIsLoading}
                    updateHasDonated={this.updateHasDonated}
                    amount={this.state.amount}
                    updateAmount={this.updateAmount}
                    payFees={this.state.payFees}
                    togglePayFees={this.togglePayFees}
                    updateFirstName={this.updateFirstName}
                    formType="Spring"
                  />
                )}
              </div>
              {this.state.isMobile && <>
                <div className="col-lg-6 info">
                  <form className="mt-4">
                  <p>
                  This Spring, nurture the potential of youth by acknowledging a special person in your life through our Thank You Tree campaign.c
                  </p>
                  <p>
                    Make a donation via the form provided and select who you
                    would like to thank!
                  </p>
                  <p>Thank someone special. Make a donation in their honour.</p>
                </form>

                <form className="mt-4">
                  <p>
                  Your support will help fund matches like Theresa and Rosemary. Theresa went from being a quiet and reserved girl to an inspirational young woman, graduating from high school and following in the footsteps of her Big Sister by getting a job in healthcare.
                  </p>
                  <YouTube videoId="jS92EDCnA9Y" opts={opts} />
                </form>
                </div>
                </>}
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default SpringCampaignComponent;