import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";

class BehalfContactForm extends React.Component {
  render() {
    const { formData, handleInputChange, toggleValue, handleChoiceUpdate } =
      this.props; // Destructure props

      const imageOptions = [
        { value: "TributeOption1", label: "Option 1", src: "/TributeOption1.png" },
        { value: "TributeOption2", label: "Option 2", src: "/TributeOption2.png" },
        { value: "TributeOption3", label: "Option 3", src: "/TributeOption3.png" },
        { value: "TributeOption4", label: "Option 4", src: "/TributeOption4.png" },
      ];

    return (
      <>
      <div className="col-md-12"><p style={{color:"black"}}>
      <small>Your name and email address will be shared with the honouree. Your donation amount will remain confidential.</small>
        </p></div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfFirstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfFirstName"
              placeholder="First Name"
              value={formData.behalfFirstName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfLastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfLastName"
              placeholder="Last Name"
              value={formData.behalfLastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

      


      </>
    );
  }
}

export default BehalfContactForm;
