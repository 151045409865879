import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class FANThankYouPage extends Component {
  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  }

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = "https://www.bigsisters.bc.ca/"; // Redirect to Big Sisters website
  }

  render() {
    return (
      <div className="thank-you-page">
        <div className="form-box">
          <form>
            <h3 style={{color: '#2dccd3'}}>{this.props.firstName ? <>Thank You, {this.props.firstName}!</> : <>Thank You!</>}</h3>
            <p style={{color:"#232323"}}>
            When you donate to Big Sisters, you’re contributing to our next generation’s future. Thank you for your generous donation. A confirmation email and charitable tax receipt has been sent to the email address provided. Please connect with Nathania at 604-873-4525 ext. 108 or nfung@bigsisters.bc.ca if you have any questions.
            </p>

            <button style={{textTransform: "none"}} className="btn btn-theme effect btn-md continue-on" type="submit"  onClick={this.handleContinue}>
            Learn More About Big Sisters of BC Lower Mainland
            </button>

            <button className="btn btn-theme effect btn-md donate-again" type="submit" 
            onClick={this.handleDonateAgain}>
            Donate Again
            </button>



          </form>
        </div>
      </div>
    );
  }
}

export default FANThankYouPage;
